<template lang="pug">
	div
		template(v-if="isVisibleData")
			.info-widgets
				InfoWidget(:value="averageMenuKpi" :label="'Среднее занятие в топ-21 категорий'")
				InfoWidget(:value="averageProductCountFirstPage" :label="'Количество SKU которые попадают на первую страницу'")
				InfoWidget(:value="averageRankFirstPage" :label="'Средняя позиция SKU в категории на первой странице'")
				InfoWidget(:value="averageProductCount" :label="'Среднее кол-во SKU категориях'")
		template(v-if="isVisibleData")
			ContainerForData(
				width="100%"
				:isLoading="isLoading"
				class="average-dynamic-chart"
			)
				template(#data)
					.flex.flex-col
						.charts
							BarChart(:chartData="averageDynamicChartData" :options="averageDynamicChartOptions")
				template(#header-left)
					.title.flex.items-center Динамика среднего занятия топа категорий, %
			ContainerForData(
				width="100%"
				:isLoading="isLoading"
				class="sku-dynamic-chart"
			)
				template(#data)
					.flex.flex-col
						.charts
							BarChart(:chartData="skuDynamicChartData" :options="skuDynamicChartOptions")
				template(#header-left)
					.title.flex.items-center Динамика кол-ва SKU на первой странице относительно среднего кол-ва SKU

		.empty-filter(v-else) Пожалуйста, выберите значения фильтра

</template>

<script>
import ContainerForData from "@/components/Nestle/ContainerForData";
import BarChart from "@/components/Chart/BarChart";
import InfoWidget from "@/components/Nestle/DynamicComponent/InfoWidget.vue";
import CogOutline from 'vue-material-design-icons/CogOutline.vue'

const GRAY_COLOR = '#A59D9D'
const GREEN_COLOR = '#67AC5B'
const LIGHT_BLACK_COLOR = '#303030'

export default {
	name: "overview-and-trend",
	components: {
		InfoWidget,
		BarChart,
		ContainerForData,
		CogOutline,
	},
	props: {
    excludeSku: {
      type: String,
      default: '',
    },
		needUpdateResult: {
			type: Boolean,
			default: false,
		},
		date: {
			type: Object,
			default: () => {},
		},
		isVisibleData: {
			type: Boolean,
			default: false,
		},
		stores: {
			type: String,
			default: '',
		},
		categories: {
			type: String,
			default: '',
		},
		brands: {
			type: String,
			default: '',
		},
    subBrands: {
      type: String,
      default: '',
    },
		regions: {
			type: String,
			default: '',
		},
		seasonProducts: {
			type: String,
			default: '',
		},
		dateFrom: {
			type: String,
			default: '',
		},
		dateTo: {
			type: String,
			default: '',
		},

	},
	data() {
		return {
			list: [],
			res: null,
			current: 1,
			limit: 20,
			offset: 0,
			isLoading: true,
		}
	},
	computed:{
		averageMenuKpi() {
			return `${this.res?.summary.average_menu_kpi || 0}%`;
		},
		averageProductCountFirstPage() {
			return this.res?.summary.average_product_count_first_page || 0;
		},
		averageRankFirstPage() {
			return this.res?.summary.average_rank_first_page || 0;
		},
		averageProductCount() {
			return this.res?.summary.average_product_count || 0;
		},
    averageDynamicChartOptions() {
			return {
				pointStyle: 'circle',
				pointRadius: 5,
				pointBorderColor: GREEN_COLOR,
				pointBackgroundColor: 'white',
				color: 'transparent',
				interaction: {
					mode: 'index'
				},
				plugins: {
					datalabels: {
						display: false,
						formatter: (index, context) => {
							if (context.dataset.label !== '0') return
							return `${index}%`
						},
						backgroundColor: 'transparent',
						borderColor: 'transparent',
						align: 'top',
						anchor: 'end',
						color: LIGHT_BLACK_COLOR,
						font: {
							weight: 'bold',
							size: 12
						}
					},
					responsive: true,
					tooltip: {
						enabled: true,
            callbacks: {
              label: (context) => {
                return `${context.parsed.y}%`;
              },
            }
					},
					scale: {
						ticks: {
							display: false,
							maxTicksLimit: 3
						}
					}
				},
				layout: {
					padding: {
						top: 20
					}
				},
				scales: {
					y: {
						display: true,
						beginAtZero: true,
						max: 100,
					}
				},
				responsive: true,
			}
		},
    skuDynamicChartOptions() {
			return {
				interaction: {
					mode: 'index'
				},
				plugins: {
					legend: {
						display: true,
						position: 'bottom',
						fontColor: LIGHT_BLACK_COLOR,
					},
					datalabels: {
						display: false,
						formatter: (index, context) => {
							if (context.dataset.label !== '0') return
							return `${index}%`
						},
						backgroundColor: 'transparent',
						borderColor: 'transparent',
						align: (context) => context.dataset.label === "0" ? 'top' : 'end',
						anchor: (context) => context.dataset.label === "0" ? 'end' : 'end',
						color: (context) => context.dataset.label === "1" ?
								'yellowgreen' : context.dataset.label === "0" ?
										LIGHT_BLACK_COLOR :  GRAY_COLOR,
						font: {
							weight: 'bold',
							size: 12
						}
					},
					responsive: true,
					tooltip: {
						enabled: true,
						callbacks: {
							label: (context) => {
								return `${context.dataset.label}: ${context.parsed.y}`;
							},
						}
					},
					scale: {
						ticks: {
							display: false,
							maxTicksLimit: 3
						}
					}
				},
				layout: {
					padding: {
						top: 20
					}
				},
				scales: {
					y: {
						display: true,
					}
				},
				responsive: true,
			}
		},
    averageDynamicChartData() {
			if (!this.res) return
			const newArr = []

			this.res.historical.list.forEach(el => {
				newArr.push(el.date || 0)
			})

			return {
				labels: newArr,
				datasets: [
					{
						label: '',
						fill: false,
						data: this.res.historical.list.map(item => Math.floor(item.average_menu_kpi)),
						borderColor: 'yellowgreen',
						backgroundColor: 'yellowgreen',
						tension: 0.25,
						type: 'line',
						order: 0,
					},
				]
			}
		},
    skuDynamicChartData() {
			if (!this.res) return
			const newArr = []
			const odd = []
			const even = []

			this.res.historical.list.forEach(el => {
				odd.push(el.average_product_count || 0)
				even.push(el.average_product_count_first_page || 0)
				newArr.push(el.date || 0)
			})

			return {
				labels: newArr,
				datasets: [
					{
						label: 'Кол-во SKU на первой странице',
						borderColor: '#49A22F',
						backgroundColor: GREEN_COLOR,
						data: even,
						order: 1,
					},
					{
						label: 'Среднее кол-во SKU',
						borderColor: '#49A22F',
						backgroundColor: GRAY_COLOR,
						data: odd,
						order: 1,
					},
				]
			}
		},
	},
	methods: {
		async fetch() {
			this.$emit('showData')

			const params = {
				stores: this.stores,
				categories: this.categories,
				brands: this.brands,
        subBrands: this.subBrands,
        excludeSku: this.excludeSku,
				regions: this.regions,
				category: this.group,
				seasonProducts: this.seasonProducts,
				dateFrom: this.dateFrom,
				dateTo: this.dateTo,
				offset: this.offset,
				limit: this.limit,
			};
			try {
				this.isLoading = true
				this.res = await this.$api.nestle.getTaxonomyPlacement(params)
			} catch (e) {
				console.log(e)
			} finally {
				this.isLoading = false
			}
		},
	},
	watch: {
		needUpdateResult: {
			immediate: true,
			async handler() {
				console.log('NeedUpateResult')
				this.offset = 0
				this.current = 1
				await this.fetch()
			}
		},
		current: {
			handler() {
				this.offset = this.limit * (this.current-1)
				this.fetch()
			},
		},
	},
}
</script>

<style lang="scss" scoped>
.count {
	&__number {
		font-size: 50px;
	}
	&__text  {
		font-size: 18px;
	}
}
.charts {
	position: relative;
	height: 300px;
}
::v-deep.chart{
	width: 1280px;
	margin: 20px;
	position: relative;
	&:hover{
		z-index: 2;
	}
}
.disabled-chart{
	pointer-events: none;
	transform: translateY(-100%);
}
.empty-filter {
	font-size: 16px;
		display: flex;
		justify-content: center;
		margin-top: 30px;
}

.info-widgets {
	display: flex;
	justify-content: space-between;
	margin-bottom: 24px;
}

.average-dynamic-chart {
	margin-bottom: 20px;
}

.title {
  color: #757575;
  font-size: 14px;
  line-height: 16px;
  font-weight: 600;
}
</style>
